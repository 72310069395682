import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findPricesV2 } from '../../../actions/PriceActions'
import utils from '../../../utils'
import dateTime from '../../../utils/dateTime'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Link } from 'react-router-dom'
import { Chip, FormControlLabel, Switch } from '@material-ui/core'
export * from '../../../hooks/useStandardTableSetup'

export enum PriceTableColumns {
  CostKeyCode = 'CostKeyCode',
  FeeScheduleName = 'FeeScheduleName',
  CostKeyLaymanDescr = 'CostKeyLaymanDescr',
  Price = 'Price',
  DateStart = 'DateStart',
  DateEnd = 'DateEnd',
  FeeScheduleDateStart = 'FeeScheduleDateStart',
  FeeScheduleDateEnd = 'FeeScheduleDateEnd',
  ExcludeFromOpp = 'ExcludeFromOpp',
  ExcludeFromSearch = 'ExcludeFromSearch',
  Notes = 'Notes',
  Networks = 'Networks',
  IsValidNow = 'IsValidNow',
}

export const defaultColumns = Object.freeze({
  [PriceTableColumns.CostKeyCode]: {
    name: 'Cost Key',
    details: {
      sortName: 'CostKey',
      dataFormat(_: any, row: any): any {
        return (
          <Link to={`/cost_key/${row?.CostKey.ID}`} target="_blank">
            {row?.CostKey?.Code}
          </Link>
        )
      },
    },
  },
  [PriceTableColumns.FeeScheduleName]: {
    name: 'Fee Schedule',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <Link to={`/fee_schedule/${row?.FeeScheduleID}`} target="_blank">
            {row?.FeeScheduleName}
          </Link>
        )
      },
    },
  },
  [PriceTableColumns.CostKeyLaymanDescr]: {
    name: (
      <DesignSuite2023.Tooltip title="Cost Key Layman Descr">
        <span style={{ textDecoration: 'underline' }}>Descr</span>
      </DesignSuite2023.Tooltip>
    ),
  },
  [PriceTableColumns.Price]: {
    name: 'Price',
    details: {
      sortName: 'Price',
      dataFormat: utils.priceFormatter,
    },
  },
  [PriceTableColumns.DateStart]: {
    name: 'Date Start',
    details: {
      sortName: 'DateStart',
      dataFormat: dateTime.cellFormatter(),
    },
  },
  [PriceTableColumns.DateEnd]: {
    name: 'Date End',
    details: {
      sortName: 'DateEnd',
      dataFormat: dateTime.cellFormatter(),
    },
  },
  [PriceTableColumns.FeeScheduleDateStart]: {
    name: 'Fee Schedule Start',
    details: {
      dataFormat: dateTime.cellFormatter(),
    },
  },
  [PriceTableColumns.FeeScheduleDateEnd]: {
    name: 'Fee Schedule End',
    details: {
      dataFormat: dateTime.cellFormatter(),
    },
  },
  [PriceTableColumns.ExcludeFromOpp]: {
    name: (
      <DesignSuite2023.Tooltip title="Is Price Excluded From Opportunity Analysis?">
        <span style={{ textDecoration: 'underline' }}>Exclude OA</span>
      </DesignSuite2023.Tooltip>
    ),
    details: {
      dataFormat: utils.boolYesNoFormatter,
    },
  },
  [PriceTableColumns.ExcludeFromSearch]: {
    name: (
      <DesignSuite2023.Tooltip title="Is Price Excluded From Public Search?">
        <span style={{ textDecoration: 'underline' }}>Exclude PS</span>
      </DesignSuite2023.Tooltip>
    ),
    details: {
      dataFormat: utils.boolYesNoFormatter,
    },
  },
  [PriceTableColumns.Notes]: {
    name: 'Notes',
  },
  [PriceTableColumns.Networks]: {
    name: 'Networks',
    details: {
      dataFormat(_: any, row: any): any {
        return (row?.Networks || []).map((n: any) => (
          <Chip key={n} size="small" label={n} />
        ))
      },
    },
  },
  [PriceTableColumns.IsValidNow]: {
    name: 'Valid Now',
    details: {
      dataFormat: utils.boolYesNoFormatter,
    },
  },
})

const defaultSort = { col: 'Price', dir: 'asc' }

export const Table = forwardRef(function FeeSchedulePriceTable(
  {
    customColumns = defaultColumns,
    apiEndpoint = findPricesV2,
    initPageSize = 25,
    DataTableProps,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize,
      DataTableProps: {
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250],
        ...(DataTableProps || {}),
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterToggleInactives(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Show Inactive"
      control={
        <Switch
          color="primary"
          checked={!filter.onlyActive}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, onlyActive: false })
              return
            }
            setFilterImmediate({ ...filter, onlyActive: true })
          }}
        />
      }
    />
  )
}
