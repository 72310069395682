import React from 'react'
import Info from '@material-ui/icons/Info'
import _ from 'lodash'
import FileLinkBtn from '../components/FileLinkBtn/FileLinkBtn'
import chbxfr from './ReactFormatters/CheckboxFormatter'
import onr from './ReactFormatters/OrgNameResolver'
import pfnr from './ReactFormatters/PFNameResolver'
import rstsr from './ReactFormatters/ReferralStatusResolver'
import Done from '@material-ui/icons/Done'
import Clear from '@material-ui/icons/Clear'
import utils from './'
import { Tooltip } from '@material-ui/core'

export function excludeFromOppAnalysisFormatter(cell, row) {
  const costKeyLosers = getLosers()
  const val = utils.boolYesNoFormatter(cell, row)
  const loser = _.includes(costKeyLosers, row.CostKey.Code)

  return (
    <div>
      {val}{' '}
      {loser && (
        <Tooltip title="Known Loser">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      )}
    </div>
  )
}

export function fileLinkFormatter(cell, row) {
  if (row && row.S3Key) {
    const s3KeyParts = row.S3Key.split('/')
    const filename = s3KeyParts[s3KeyParts.length - 1]
    if (row.FileType) {
      const { IsPHIType, IsLoaType } = row.FileType
      return (
        <FileLinkBtn
          phi={IsPHIType}
          loa={IsLoaType}
          fileId={row.ID}
          s3Key={row.S3Key}
          filename={filename}
          variant="outlined"
        />
      )
    }
    return (
      <FileLinkBtn
        fileId={row.ID}
        s3Key={row.S3Key}
        filename={filename}
        variant="outlined"
      />
    )
  }
  console.warn('Called fileLinkFormatter without any file data: ', row)

  return ''
}

export function fileTypeLinksFormatter(cell, row) {
  if (row.Files && row.Files.length > 0) {
    const links = row.Files.map((file) => {
      const type = file.FileType.Description || 'Unknown'

      return (
        <div key={file.S3Key}>
          <FileLinkBtn
            loa={file.FileType.IsLoaType}
            phi={file.FileType.IsPHIType}
            fileId={file.ID}
            s3Key={file.S3Key}
            filename={type}
          />
        </div>
      )
    })

    return <div>{links}</div>
  }

  return '-'
}

export class ActiveFormatter extends React.Component {
  render() {
    const { active } = this.props
    if (active) {
      return <Done />
    }
    return <Clear />
  }
}

export const OrgNameResolver = onr
export const PFNameResolver = pfnr
export const getCheckboxFormatter = chbxfr
export const ReferralStatusResolver = rstsr

// @todo: WHAT TF IS THIS? See excludeFromOppAnalysisFormatter
// arrrggghhh
function getLosers() {
  return [
    '11400',
    '11401',
    '11402',
    '11406',
    '11420',
    '11421',
    '11423',
    '11441',
    '11444',
    '11641',
    '11642',
    '11730',
    '11750',
    '20610',
    '52000',
    '55700',
    '58300',
    '66984',
    '67840',
    'E0601',
  ]
}
