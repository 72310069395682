import React, { forwardRef, useContext, useMemo, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findOpenPayables } from '../../../actions/ZClaimFundingActions'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewPayableDetailOverlay from './ViewPayableDetailOverlay'
import {
  getPayablePayeeTypeDisplay,
  getPayableStatusLabel,
  payablePayeeType,
  PayableStatus,
} from './PayableDetail'
//@ts-ignore
import styled from 'styled-components'
import ManualPay from './ManualPay'
import WriteoffFarleyUncollectible from './WriteoffFarleyUncollectible'
import statusDot from './StatusDot'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import IconEdit from '@material-ui/icons/Edit'
import DialogTransferPayableBillingEntity from './DialogTransferPayableBillingEntity'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export interface payableTableRecordDetail {
  ZClaimID: number
  ZClaimAmount: string
}

export interface payableTableRecord {
  RecordID: string
  PayEntityID: number
  PayEntityName: string
  PayEntityType: payablePayeeType
  PayEntityAddress: string
  PayEntityIsLatest: boolean
  ClaimCount: number
  PayableStatus: PayableStatus
  LastCollectedOn: string | null
  Amount: string
  Details: payableTableRecordDetail[]
}

export const defaultColumns = (isWorking: boolean) =>
  Object.freeze({
    ViewPayableDetails: {
      name: '',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          return <InlineViewPayableDetail data={row} />
        },
      },
    },
    PayEntityType: {
      name: 'Type',
      details: { dataFormat: getPayablePayeeTypeDisplay },
    },
    PayEntityName: {
      name: 'Payee',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          return (
            <div>
              <div>
                {!row.PayEntityIsLatest && (
                  <DesignSuite2023.Tooltip title="Pay entity is not latest version">
                    <statusDot.Dot color={statusDot.Colors.RED} />
                  </DesignSuite2023.Tooltip>
                )}
                {row.PayEntityName}
                {row.PayEntityType === payablePayeeType.BillingEntity && (
                  <InlineTransferPayableBillingEntity data={row} />
                )}
              </div>
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {row.PayEntityAddress}
              </div>
            </div>
          )
        },
      },
    },
    ClaimCount: { name: 'Claim Count' },
    PayableStatus: {
      name: 'Payable Status',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          return getPayableStatusLabel(row.PayableStatus as PayableStatus)
        },
      },
    },
    LastCollectedOn: {
      name: 'Last Collected On',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          const today = new Date()
          const lastCollectedOn = row.LastCollectedOn
            ? new Date(row.LastCollectedOn)
            : null
          const style =
            lastCollectedOn && lastCollectedOn > today ? { color: 'aqua' } : {}
          return <div style={style}>{row.LastCollectedOn}</div>
        },
      },
    },
    Amount: {
      name: 'Amount',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          const amount = parseFloat(row.Amount)
          const style = amount < 0 ? { color: 'red' } : {}
          return <div style={style}>{formatAmount(row.Amount)}</div>
        },
      },
    },
    ManualPaymentButton: {
      name: '',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          return parseFloat(row.Amount) > 0 ? (
            <InlineManualPaymentButton data={row} disabled={isWorking} />
          ) : null
        },
      },
    },
    WriteoffFarleyUncollectibleButton: {
      name: '',
      details: {
        dataFormat(_: any, row: payableTableRecord): any {
          return parseFloat(row.Amount) < 0 &&
            row.PayEntityName === 'JP Farley' ? (
            <InlineWriteoffFarleyUncollectibleButton
              data={row}
              disabled={isWorking}
            />
          ) : null
        },
      },
    },
  })

const defaultSort = { col: 'PayEntityName', dir: 'asc' }

type props = {
  isWorking: boolean
  setIsWorking(isWorking: boolean): void
}

export const Table = forwardRef(function Table(
  {
    isWorking,
    setIsWorking,
    apiEndpoint = findOpenPayables,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any> & props,
  ref: any
): React.ReactElement | null {
  const customColumns = useMemo(() => {
    return defaultColumns(isWorking)
  }, [isWorking])

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 25,
      DataTableProps: {
        isWorkingCallback: setIsWorking,
        isRowCheckable: (row: payableTableRecord) => {
          const today = new Date()
          const lastCollectedOn = row.LastCollectedOn
            ? new Date(row.LastCollectedOn)
            : null
          return (
            !isWorking &&
            row.PayEntityType === payablePayeeType.BillingEntity &&
            parseFloat(row.Amount) >= 0 &&
            (lastCollectedOn === null || lastCollectedOn <= today)
          )
        },
        keyProp: 'RecordID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineTransferPayableBillingEntity({
  data,
}: {
  data: payableTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          ref?.current?.open()
        }}>
        <IconEdit fontSize="inherit" />
      </IconButton>
      <DialogTransferPayableBillingEntity
        ref={ref}
        record={data}
        onClose={() => refresh()}
      />
    </>
  )
}

function InlineViewPayableDetail({
  data,
}: {
  data: payableTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewPayableDetailOverlay
        ref={ref}
        payEntityID={data.PayEntityID}
        payableStatus={data.PayableStatus}
        onClose={() => refresh()}
      />
    </>
  )
}

function InlineManualPaymentButton({
  data,
  disabled,
}: {
  data: payableTableRecord
  disabled: boolean
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)

  return (
    <ManualPay
      disabled={disabled}
      payEntityID={data.PayEntityID}
      payableStatus={data.PayableStatus}
      onClose={() => refresh()}
    />
  )
}

function InlineWriteoffFarleyUncollectibleButton({
  data,
  disabled,
}: {
  data: payableTableRecord
  disabled: boolean
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)

  return (
    <WriteoffFarleyUncollectible
      data={data}
      disabled={disabled}
      onClose={() => refresh()}
    />
  )
}

type FilterPayableStatusProps = {
  disabled: boolean
}

export function FilterPayableStatus({
  disabled,
}: FilterPayableStatusProps): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const [statuses, setStatuses] = React.useState<PayableStatus[]>([
    PayableStatus.BilledCollected,
    PayableStatus.EopOnly,
  ])
  const { setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(PayableStatus).map((value: PayableStatus) => ({
      value,
      label: getPayableStatusLabel(value),
    }))
  })

  return (
    <FormControl
      margin="none"
      size="small"
      fullWidth
      component="fieldset"
      style={{
        minWidth: 170,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '8px',
      }}>
      <FormLabel
        style={{ fontSize: '0.75rem', marginBottom: '8px' }}
        key={internalID}>
        Payable Status
      </FormLabel>
      <FormGroup>
        {items.map((item: any) => (
          <FormControlLabel
            key={item.value}
            control={
              <Switch
                disabled={disabled}
                size="small"
                checked={statuses.includes(item.value)}
                onChange={(e) => {
                  const newStatuses = e.target.checked
                    ? [...statuses, item.value]
                    : statuses.filter((status) => status !== item.value)
                  setStatuses(newStatuses)
                  setFilterImmediate({ payableStatuses: newStatuses })
                }}
                name={item.label}
              />
            }
            label={item.label}
            labelPlacement="end"
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

type FilterPayeeTypeProps = {
  disabled: boolean
}

export function FilterPayeeType({
  disabled,
}: FilterPayeeTypeProps): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(payablePayeeType).map((value: payablePayeeType) => ({
      value,
      label: getPayablePayeeTypeDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ payEntityTypes: null })
    }
    setFilterImmediate({ payEntityTypes: [sel.props.value] })
  }

  const v = filter.payEntityTypes || payablePayeeType.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payee Type
      </InputLabel>
      <Select
        disabled={disabled}
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payee Type"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

type FilterPayEntityIsLatestProps = {
  disabled: boolean
}

export function FilterPayEntityIsLatest({
  disabled,
}: FilterPayEntityIsLatestProps): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  const items = [
    { value: 'all', label: 'All' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]

  function getFilterValue(value: string): boolean | null {
    switch (value) {
      case 'yes':
        return true
      case 'no':
        return false
    }
    return null
  }

  function getValueFromFilter(value: boolean | null): string {
    if (value === null || value === undefined) {
      return 'all'
    }

    return value ? 'yes' : 'no'
  }

  const onChange = (_: any, sel: any) => {
    setFilterImmediate({ payEntityIsLatest: getFilterValue(sel.props.value) })
  }

  const v = getValueFromFilter(filter.payEntityIsLatest)

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Pay Entity Is Latest
      </InputLabel>
      <Select
        disabled={disabled}
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Pay Entity Is Latest"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
