import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { payablePayeeType, payableRecord, PayableStatus } from './PayableDetail'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  renderNumericField as RenderNumericField,
  renderSwitchField as RenderSwitchField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  getPayable,
  ManualPaymentType,
  PayItem,
  postSendManualPayment,
  PostSendManualPaymentParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'

type props = {
  payEntityID: number
  payableStatus: PayableStatus
  disabled: boolean
  onClose?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      min-width: 0;
    }
  }
`

export default function ManualPay({
  payEntityID,
  payableStatus,
  disabled,
  onClose,
}: props): React.ReactElement {
  function initialPostSendManualPaymentParams(): PostSendManualPaymentParams {
    return {
      paymentDate: dateTime.now().format(dateTime.formats.ISODate),
      manualPayType: ManualPaymentType.CHECK,
      checkNumber: null,
      achInfo: null,
      sendEOP: data.PayEntityType === payablePayeeType.BillingEntity,
      notes: null,
      payItems: data.Details.map(
        (detail): PayItem => ({
          zClaimID: detail.ZClaimID,
          payEntityID: data.PayEntityID,
          amount: detail.ZClaimAmount,
        })
      ),
    }
  }

  const [data, setData] = useState<payableRecord>({
    PayEntityID: 0,
    PayEntityName: '',
    PayEntityType: payablePayeeType.All,
    PayEntityAddress: '',
    ClaimCount: 0,
    PayableStatus: '',
    LastCollectedOn: '',
    Amount: '',
    Details: [],
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postSendManualPaymentParams, setPostSendManualPaymentParams] =
    useState<PostSendManualPaymentParams>(initialPostSendManualPaymentParams())

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setPostSendManualPaymentParams(initialPostSendManualPaymentParams())
  }, [data])

  useEffect(() => {
    getData()
  }, [isOpen])

  function getData() {
    if (!isOpen) return
    return getPayable(payEntityID, payableStatus)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get payable data',
        })
      )
  }

  const setter = ({ name, value }: SetterArgs) => {
    const newValue =
      name === 'checkNumber' ? (value === null ? null : Number(value)) : value
    setPostSendManualPaymentParams((curr: PostSendManualPaymentParams) => ({
      ...curr,
      [name]: newValue,
    }))
  }

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  function doPostManualPayment() {
    setIsWorking(true)
    let params: PostSendManualPaymentParams = postSendManualPaymentParams

    postSendManualPayment(params)
      .then((res: any) => {
        showSnackbar(`Manual payment posted OK`, SnackbarTypeSuccess)
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting manual payment',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        variant="outlined"
        disabled={disabled}
        size="small"
        color="secondary"
        onClick={() => {
          setIsOpen(true)
        }}>
        Manual Pay
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>Manual Pay: {data.PayEntityName}</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="paymentDate"
                  label="Payment Date"
                  value={postSendManualPaymentParams.paymentDate}
                  setter={setter}
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography variant="h6">
                  {formatAmount(data.Amount)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RenderSwitchField
                  name="sendEOP"
                  label="Send EOP"
                  opts={{
                    disabled:
                      data.PayEntityType !== payablePayeeType.BillingEntity,
                  }}
                  value={postSendManualPaymentParams.sendEOP}
                  setter={setter}
                />
                {data.PayEntityType !== payablePayeeType.BillingEntity && (
                  <>
                    <br />
                    <Typography
                      variant="caption"
                      style={{ fontStyle: 'italic' }}>
                      can only Send EOPs to providers, not {data.PayEntityType}s
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <RadioGroup
                    row
                    name="manualPayType"
                    value={postSendManualPaymentParams.manualPayType}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      setter({ name: ev.target.name, value: ev.target.value })
                      if (ev.target.value !== ManualPaymentType.CHECK) {
                        setter({ name: 'checkNumber', value: null })
                      }
                    }}>
                    <FormControlLabel
                      value={ManualPaymentType.ACH}
                      control={<Radio />}
                      label="ACH"
                    />
                    <FormControlLabel
                      value={ManualPaymentType.CHECK}
                      control={<Radio />}
                      label="Check"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <RenderNumericField
                  name="checkNumber"
                  label="Check Number"
                  value={postSendManualPaymentParams.checkNumber || ''}
                  opts={{
                    hidden:
                      postSendManualPaymentParams.manualPayType !==
                      ManualPaymentType.CHECK,
                  }}
                  setter={setter}
                />
                <RenderTextField
                  name="achInfo"
                  label="ACH Info"
                  value={postSendManualPaymentParams.achInfo}
                  opts={{
                    hidden:
                      postSendManualPaymentParams.manualPayType !==
                      ManualPaymentType.ACH,
                  }}
                  setter={setter}
                />
              </Grid>

              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postSendManualPaymentParams.notes}
                  setter={setter}
                />
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking ||
                    !postSendManualPaymentParams.paymentDate ||
                    (postSendManualPaymentParams.manualPayType ===
                      ManualPaymentType.CHECK &&
                      !postSendManualPaymentParams.checkNumber) ||
                    (postSendManualPaymentParams.manualPayType ===
                      ManualPaymentType.ACH &&
                      !postSendManualPaymentParams.achInfo)
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doPostManualPayment}>
                  Post Manual Payment
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner size={20} show />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
