import React, { useRef, useState } from 'react'
import * as PayableTable from '../Components/PayableTable'
import {
  payableTableRecord,
  payableTableRecordDetail,
} from '../Components/PayableTable'
import { IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { PayableStatus } from '../Components/PayableDetail'
import { PayItems } from '../../../actions/ZClaimFundingActions'
import ButtonDoZelisPayment from '../Components/ButtonDoZelisPayment'

export default function PageApply(): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<payableTableRecord>>(
    []
  )
  const [isWorking, setIsWorking] = useState(false)
  const refPayableTable = useRef<any>(null)

  const payItems: PayItems = checkedItems.flatMap(
    (item: payableTableRecord): PayItems =>
      Array.isArray(item.Details)
        ? item.Details.map((detail: payableTableRecordDetail) => ({
            zClaimID: detail.ZClaimID,
            payEntityID: item.PayEntityID,
            amount: detail.ZClaimAmount,
          }))
        : []
  )

  const pageColumnLeft = (
    <div className="lefty">
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refPayableTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonDoZelisPayment
          isWorkingCallback={setIsWorking}
          disabled={isWorking || (checkedItems || []).length === 0}
          postSendZelisPaymentsParams={payItems}
          paymentCount={checkedItems.length}
          paymentAmount={checkedItems
            .map((item) => parseFloat(item.Amount))
            .reduce((a, b) => a + b, 0)}
          onComplete={() => {
            refPayableTable.current?.refresh()
          }}
        />
      </div>
      <DesignSuite2023.Divider />
      <div style={{ paddingBottom: '1rem' }}>
        <PayableTable.StandardFilterSearch
          disabled={isWorking}
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <PayableTable.FilterPayeeType disabled={isWorking} />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <PayableTable.FilterPayableStatus disabled={isWorking} />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <PayableTable.FilterPayEntityIsLatest disabled={isWorking} />
      </div>
    </div>
  )

  return (
    <PayableTable.Table
      passFilters={{
        payableStatuses: [PayableStatus.BilledCollected, PayableStatus.EopOnly],
      }}
      isWorking={isWorking}
      isWorkingCallback={setIsWorking}
      ref={refPayableTable}
      className="full-fixed"
      enableURLReflection="zclaims.payable.list"
      onCheckHandler={setCheckedItems}
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
