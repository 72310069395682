import React, { useEffect, useState } from 'react'
import { useAuth } from '../../providers/Auth'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import DesignSuite2023 from '../DesignSuite2023'
import zcLogo from '../../assets/zc-logo-2020.png'
// import zcLogoLight from '../../assets/zc-logo-2020-light.png'

const StyledAppFrameSidebar = styled.nav`
  &.app-frame-sidebar {
    width: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    opacity: 1;
    transition: all 0.45s ease-in-out;

    .zero-logo {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      position: relative;

      img {
        height: 40%;
        width: auto;
        object-fit: contain;
      }
    }

    .sidebar-primary {
      flex: 1;
    }

    .nav-list {
      margin: 0;
      padding: 1rem 0 0;
      font-size: 0.9rem;
      font-weight: 500;
      white-space: nowrap;

      li {
        padding: 0;
        line-height: normal;

        .nav-item {
          margin: 0;
          line-height: inherit;

          span {
            font-weight: inherit;
            line-height: inherit;

            a {
              display: block;
              padding: 0.65rem 2rem 0.65rem 1.5rem;
              color: inherit;
              position: relative;
              line-height: inherit;
              opacity: 0.6;

              &:focus,
              &:active {
                text-decoration: none;
              }

              &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 100%;
                background: #51b8c7;
                transition: all 0.1s ease-in;
              }

              &.active,
              &:hover {
                background: rgba(0, 0, 0, 0.075);
                opacity: 1;

                &:before {
                  width: 8px;
                }
              }
            }
          }
        }
      }
    }

    .sidebar-foot {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-top: 1px dotted #aaa;
    }
  }
`

interface navItem {
  label: string
  url: string
  perm: string[]
}

interface navItemProps {
  item: navItem
}

function NavItem({ item }: navItemProps): React.ReactElement {
  return (
    <ListItem disableGutters>
      <ListItemText
        className="nav-item"
        primary={
          <NavLink
            to={item.url}
            data-testid={`nav-${item.label.replace(' ', '-')}`}>
            {item.label}
          </NavLink>
        }
        primaryTypographyProps={{
          variant: 'body1',
          style: { fontSize: 'inherit' },
        }}
      />
    </ListItem>
  )
}

export default function AppFrameSidebar(): React.ReactElement | null {
  const [navItems, setNavItems] = useState<navItem[]>([])
  const [specializedNavItems, setSpecializedNavItems] = useState<navItem[]>([])
  const { perms, isAuthenticated, loading: authLoading } = useAuth()

  useEffect(() => {
    const permSet = new Set(perms)
    setNavItems(
      standardNavItems.filter(
        (i: any) => i.perm.filter((p: string) => permSet.has(p)).length > 0
      )
    )

    setSpecializedNavItems(
      specialtyNavItems.filter(
        (i: any) => i.perm.filter((p: string) => permSet.has(p)).length > 0
      )
    )
  }, [perms])

  const logo = (
    <div className="zero-logo">
      {/* <img alt='logo' src="https://tzc-prd-assets.s3.us-west-2.amazonaws.com/images/Zero-MasterLogo-dark.png" width="80%"/> */}
      {/* <img className='zc-logo-light' alt='logo' src={zcLogoLight} /> */}
      <img className="zc-logo-dark" alt="logo" src={zcLogo} />
    </div>
  )

  if (authLoading || !isAuthenticated) {
    return (
      <StyledAppFrameSidebar className="app-frame-sidebar">
        {logo}
        <div className="sidebar-primary" style={{ textAlign: 'center' }}>
          <DesignSuite2023.LoadingSpinner />
        </div>
      </StyledAppFrameSidebar>
    )
  }

  return (
    <StyledAppFrameSidebar className="app-frame-sidebar">
      {logo}

      <div className="sidebar-primary">
        <List className="nav-list">
          {navItems?.map((item: any) => <NavItem item={item} key={item.url} />)}
          {specializedNavItems?.length > 0 ? (
            <Divider light variant="middle" />
          ) : null}
          {specializedNavItems?.map((item: any) => (
            <NavItem item={item} key={item.url} />
          ))}
        </List>
      </div>

      <div className="sidebar-foot">
        <small>Build: {process.env.REACT_APP_CURRENT_HASH}</small>
      </div>
    </StyledAppFrameSidebar>
  )
}

const standardNavItems = Object.freeze([
  { label: 'Cost Keys', url: '/cost_keys', perm: ['r:master_data'] },
  {
    label: 'Eligibility',
    url: '/eligibility_console',
    perm: ['r:eligibility'],
  },
  { label: 'Employers', url: '/employers', perm: ['r:employer'] },
  { label: 'Fee Schedules', url: '/fee_schedules', perm: ['r:provider'] },
  { label: 'Invoices', url: '/invoices', perm: ['r:invoice'] },
  { label: 'LOA', url: '/loas', perm: ['r:loa'] },
  { label: 'Networks', url: '/networks', perm: ['r:configurable_network'] },
  {
    label: 'Opportunity Analysis',
    url: '/opportunity_analysis',
    perm: ['r:prospect_claim'],
  },
  { label: 'Organizations', url: '/organizations', perm: ['r:provider'] },
  { label: 'Partners', url: '/partners', perm: ['r:partner'] },
  { label: 'Provider Search', url: '/search', perm: ['r:provider_prices'] },
  { label: 'Referrers', url: '/referrers', perm: ['r:referrer'] },
  {
    label: 'Savings Review',
    url: '/savings_review',
    perm: ['r:zero_card_claim'],
  },
  {
    label: 'Stop Loss',
    url: '/stop_loss_contracts',
    perm: ['r:employer_stoploss'],
  },
  { label: 'Users and Groups', url: '/users', perm: ['r:user'] },
  {
    label: 'ZClaims',
    url: '/zclaims',
    perm: ['a:engineering', 'r:provider_prices'],
  },
])

const specialtyNavItems = Object.freeze([
  { label: 'Engineering', url: '/engineering', perm: ['a:engineering'] },
  { label: 'ETL', url: '/etl', perm: ['a:etl'] },
  { label: 'Marketing', url: '/marketing', perm: ['a:marketing'] },
])
