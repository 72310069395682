import React from 'react'
import utils from '../utils'
import dateTime from '../utils/dateTime'

const READ_PERMISSION = 's:referral'

const referral = {
  columns: {
    Descr: { name: 'Description', details: {} },
    CreatedAt: {
      name: 'Created On',
      details: { dataFormat: dateTime.cellFormatter() },
    },
    Organization: {
      name: 'Organization',
      details: {
        dataFormat: (_, row) => {
          return (
            <a
              href={`${window.location.origin}/organization/${row.OrganizationID}`}
              rel="noreferrer"
              target="_blank">
              {row.OrganizationName}
            </a>
          )
        },
      },
    },
    Facility: {
      name: 'Facility',
      details: {
        dataFormat: (_, row) => {
          return (
            <a
              href={`${window.location.origin}/organization/${row.OrganizationID}/practice_facility/${row.PracticeFacilityID}`}
              rel="noreferrer"
              target="_blank">
              {row.PracticeFacilityName}
            </a>
          )
        },
      },
    },
    StatusDescription: {
      name: 'Status',
      details: { width: '100px' },
    },
    Notes: { name: 'Notes', details: {} },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
  getMemberName,
}

function getMemberName(cell, row) {
  const { FirstName, LastName, FirstNameAka, LastNameAka } = cell
  let first = FirstName
  let last = LastName

  if (FirstNameAka !== undefined && FirstNameAka !== '') {
    first = FirstNameAka
  }
  if (LastNameAka !== undefined && LastNameAka !== '') {
    last = LastNameAka
  }

  return `${first} ${last}`
}

export default referral
